<template>
    <div class="AllTree" style="height: 100%">
        <el-row :gutter="20" style="height: 100%;width: 100%">
            <el-col :span="6" style="height: 100%;border-right: solid 1px #e6e6e6;">
                <div class="grid-content bg-purple">
                    <span>当前展示系统：</span>
                    <el-select v-model="value" placeholder="请选择" style="margin-top: 10px" @change="selectAccMenuView">
                        <el-option
                                v-for="item in options"
                                :key="item.path"
                                :label="item.menuName"
                                :value="item.menuId">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="18" style="height: 100%;">
                <div class="grid-content bg-purple menuAllView">
                    <el-menu
                            :collapse="navShow"
                            class="el-menu-vertical-demo menu"
                            unique-opened
                            @open="handleOpen"
                    >
                        <template v-for="(menu_one,i) in menuData.children">
                            <el-submenu v-if=""  :key="i" :index="menu_one.path">
                                <template slot="title">
                                    <i :class=""></i>
                                    <span>{{menu_one.menuName}}</span>
                                </template>

                                <el-menu-item
                                        v-for="(menu_two,i) in menu_one.children"
                                        :key="i"
                                        :index="menu_two.path"
                                >
                                    <i :class=""></i>
                                    <span>{{menu_two.menuName}}</span>
                                </el-menu-item>
                            </el-submenu>
                        </template>
                    </el-menu>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    //引入组件
    import axios from 'axios'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                navShow: false, //导航是否折叠
                menuData: {},
                allMenuViewData:[],
                value:'1',
                options:[]
            }
        },
        mounted:function () {
            axios.post(`${this.$apiPath}/api/pm/menu/queryMenusAllTree/`).then(res=>{
                const startMenu = res.data[0]
                this.allMenuViewData = startMenu;
                this.options = startMenu.children;
                const mymetan = {};
                const children = [];
                children.push(startMenu.children[0]);
                mymetan.children = children;
                this.menuData = mymetan;
            })
        },
        methods:{
            selectAccMenuView(setVal){
                if (setVal == '1') {
                    const mymetan = {};
                    const children = [];
                    children.push(this.allMenuViewData.children[0]);
                    mymetan.children = children;
                    this.menuData = mymetan;
                }else if(setVal == '2'){
                    this.menuData = this.allMenuViewData.children[1]
                }else if(setVal == '3'){
                    this.menuData = this.allMenuViewData.children[2];
                }
            },
            handleOpen(key, keyPath){
                //后备
            }
        }
    }
</script>
<style scoped>
    .menuAllView{
        width: 400px;
    }
    .menuAllView ul li{
        border-bottom: solid 1px #e6e6e6;
    }
    .menuAllView ul{
        border-top: solid 1px #e6e6e6;
    }
    .AllTree{
        width: 100%;
        height: 100%;
    }
      .text {
          font-size: 14px;
      }

    .item {
        margin-bottom: 18px;
    }

</style>
